import './masonry';
import Swiper, { Autoplay, Navigation } from 'swiper';
Swiper.use([Autoplay, Navigation]);

import 'swiper/swiper.scss';

document.addEventListener('DOMContentLoaded', () => {
  new Swiper('.swiper-container', {
    autoplay: {
      disableOnInteraction: false,
      delay: 5000,
    },
    slidesPerView: 1,
    centeredSlides: true,
    spaceBetween: 2,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    loop: true,
    breakpoints: {
      767: {
        slidesPerView: 3,
        centeredSlides: true,
      },
    },
    on: {
      imagesReady() {
        document.querySelector('.swiper-container').style.visibility =
          'visible';
        document.querySelector('.swiper-container').style.opacity = 1;
      },
    },
  })
})

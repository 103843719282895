import jQuery from "jquery";
import jQueryBridget from "jquery-bridget";
import Masonry from "masonry-layout";
import imagesLoaded from "imagesloaded";

jQueryBridget('masonry', Masonry, jQuery)

let msnry;
const options = {
  columnWidth: 300,
  itemSelector: ".gridItem",
  gutter: 1,
  percentPosition: true,
}

const generate = (options) => {
  return new Masonry(document.querySelector("#gridItems"), options);
}

jQuery(window).on("load resize", () => {
  var gridItems = document.querySelector("#gridItems"),
    window_size = window.innerWidth;
  gridItems.style.visibility = "hidden";
  var viewSize = gridItems.clientWidth;
  var gridSize = document.querySelector(".gridItem:first-child").clientWidth;
  if (window_size <= 320) {
    gridSize = viewSize;
  } else if (321 <= window_size && window_size <= 740) {
    gridSize = viewSize / 2 - 1;
  } else if (741 <= window_size && window_size <= 1500) {
    gridSize = viewSize / 4 - 1;
  } else if (1501 <= window_size && window_size <= 1999) {
    gridSize = viewSize / 8 - 1;
  } else if (2000 <= window_size) {
    gridSize = 2000 / 8 - 1;
  }

  new imagesLoaded(document.querySelector("#gridItems"), (selector) => {
    options.columnWidth = gridSize

    if (msnry) {
      msnry.destroy()
    }

    msnry = generate(options)
  });
  gridItems.style.visibility = "visible";
});
